exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-single-js": () => import("./../../../src/pages/blog-single.js" /* webpackChunkName: "component---src-pages-blog-single-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nossas-olimpiadas-js": () => import("./../../../src/pages/nossas-olimpiadas.js" /* webpackChunkName: "component---src-pages-nossas-olimpiadas-js" */),
  "component---src-pages-o-instituto-js": () => import("./../../../src/pages/o-instituto.js" /* webpackChunkName: "component---src-pages-o-instituto-js" */),
  "component---src-pages-o-que-fazemos-js": () => import("./../../../src/pages/o-que-fazemos.js" /* webpackChunkName: "component---src-pages-o-que-fazemos-js" */),
  "component---src-pages-quero-apoiar-js": () => import("./../../../src/pages/quero-apoiar.js" /* webpackChunkName: "component---src-pages-quero-apoiar-js" */),
  "component---src-template-blog-post-js": () => import("./../../../src/template/blog-post.js" /* webpackChunkName: "component---src-template-blog-post-js" */)
}

